import { Component, OnInit } from '@angular/core';
import { doc, getDoc, onSnapshot, setDoc } from 'firebase/firestore';
import { User, TownshipUser, Township } from '../interfaces';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../../environments/environment';
import { ChangeTownshipDialogComponent } from './dialog/change-township-dialog/change-township-dialog.component';
import { Auth, getAuth } from 'firebase/auth';
import { db } from '../app.component';
import moment from 'moment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard-township',
  templateUrl: './dashboard-township.component.html',
  styleUrls: ['./dashboard-township.component.scss'],
})
export class DashboardTownshipComponent implements OnInit {
  env = environment;
  township: Township;
  isHelpdeskUser: boolean = false;
  isFinancialUser: boolean = false;
  isControllerUser: boolean = false;
  isStatisticsUser: boolean = false;
  isAdministrator: boolean = false;
  isOwner: boolean = false;
  isSuperUser: boolean = false;
  visibleVoucherGroups: number;
  visibleOrganisations: number;
  loaded: boolean = false;

  public chartScheme = { domain: ['#b1e4c8', '#7ad0a1', '#5ac288', '#46b473'] };

  constructor(private router: Router, public dialog: MatDialog) {}

  async ngOnInit() {
    const auth = getAuth();
    if (auth.currentUser) {
      const userDoc = doc(db, `users/${auth.currentUser.uid}`);
      const user = (await getDoc(userDoc)).data() as User;
      if (this.env.production && user.rights !== 'admin') {
        await this.autoLogoutIfNecessary(auth, user);
      }
      if (user.rights === 'admin') {
        this.isSuperUser = true;
      }
      const townshipDoc = doc(
        db,
        `township/${user.township}/users/${auth.currentUser.uid}`
      );
      const townshipUser = (await (
        await getDoc(townshipDoc)
      ).data()) as TownshipUser;
      switch (townshipUser?.rights) {
        case 'helpdesk':
          this.isHelpdeskUser = true;
          break;
        case 'financial':
          this.isFinancialUser = true;
          break;
        case 'controller':
          this.isControllerUser = true;
          break;
        case 'statistics':
          this.isStatisticsUser = true;
          break;
        case 'admin':
          this.isAdministrator = true;
          break;
        case 'owner':
          this.isOwner = true;
          break;
      }
      if (user.rights === 'admin' && this.env.env === 'township') {
        if (
          !user.township &&
          localStorage.getItem('township') === 'undefined'
        ) {
          let preventDoubleOpening = false;
          if (!preventDoubleOpening) {
            preventDoubleOpening = true;
            const dialogRef = this.dialog.open(ChangeTownshipDialogComponent, {
              width: '475px',
              data: { mustSelect: true },
              disableClose: true,
            });
            dialogRef.afterClosed().subscribe(async (townshipId) => {
              if (townshipId && typeof townshipId !== 'undefined') {
                await setDoc(
                  doc(db, `users/${auth.currentUser.uid}`),
                  { township: townshipId },
                  { merge: true }
                );
                const townshipSnap = onSnapshot(
                  doc(db, `/township/${townshipId}`),
                  (doc) => {
                    const township = doc.data() as Township;
                    this.township = township;
                    const hiddenVoucherGroups = township.hiddenVoucherGroups
                      ? township.hiddenVoucherGroups
                      : 0;
                    this.visibleVoucherGroups =
                      township.totalVoucherGroups - hiddenVoucherGroups;
                    const hiddenOrganisations = township.hiddenOrganisations
                      ? township.hiddenOrganisations
                      : 0;
                    this.visibleOrganisations =
                      township.totalOrganisations - hiddenOrganisations;
                  }
                );
              }
            });
          }
        }
      }
      if (!user.organisation) {
        const townshipSnap = onSnapshot(
          doc(db, `/township/${user.township}`),
          (doc) => {
            this.township = doc.data() as Township;
            const hiddenVoucherGroups = this.township.hiddenVoucherGroups
              ? this.township.hiddenVoucherGroups
              : 0;
            this.visibleVoucherGroups =
              this.township.totalVoucherGroups - hiddenVoucherGroups;
            const hiddenOrganisations = this.township.hiddenOrganisations
              ? this.township.hiddenOrganisations
              : 0;
            this.visibleOrganisations =
              this.township.totalOrganisations - hiddenOrganisations;
          }
        );
      }
    }
    if (localStorage.getItem('loggedIn')) {
      localStorage.removeItem('loggedIn');
      location.reload();
    }
    this.loaded = true;
  }

  async autoLogoutIfNecessary(auth: Auth, user: User) {
    let difference = 0;
    if (user.lastLoginDate) {
      const lastLoginMoment = moment(user.lastLoginDate.toDate());
      const now = moment(Date.now());
      difference = now.diff(lastLoginMoment, 'hours');
    }
    if (!user.lastLoginDate || difference >= 24) {
      await auth.signOut();
      location.reload();
    }
  }

  openUrl() {
    let url = 'https://dev.planning.lokalebon.nl';
    if (this.router.url.match(/^beheer$/)) {
      url = this.router.url.replace('beheer', 'planning');
    }
    window.open(url, '_blank');
  }
}
