import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Appointment, PlanningUser } from 'src/app/interfaces';
import { ManageAppointmentComponent } from '../../dialogs/manage-appointment/manage-appointment.component';
import moment from 'moment';
import { AppointmentDetailsComponent } from '../../dialogs/appointment-details/appointment-details.component';

export interface AppointmentInfoData {
  appointment: Appointment;
  showEditButton: boolean;
  planningUsers: PlanningUser[];
}

@Component({
  selector: 'app-appointment-info-card',
  templateUrl: './appointment-info-card.component.html',
  styleUrls: ['./appointment-info-card.component.scss'],
})
export class AppointmentInfoCardComponent implements OnInit {
  moment = moment;
  appointmentUsers: PlanningUser[] = [];
  @Input() data: AppointmentInfoData;

  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<AppointmentDetailsComponent>
  ) {}

  ngOnInit() {
    moment.locale('nl');

    this.data.appointment.planningUserIds?.forEach((userId) => {
      const user = this.getPlanningUser(userId);
      if (user) this.appointmentUsers.push(user);
    });
  }

  capitalizeFirstLetter(val: string) {
    return String(val).charAt(0).toUpperCase() + String(val).slice(1);
  }

  getPlanningUser(id: string) {
    const planningUser = this.data.planningUsers.find((user) => user.id === id);
    return planningUser;
  }

  editAppointment() {
    const dialogRef = this.dialog.open(ManageAppointmentComponent, {
      width: '500px',
      data: {
        appointment: this.data.appointment,
        planningUsers: this.data.planningUsers,
      },
      disableClose: true,
      panelClass: ['fullscreen-dialog'],
    });
    dialogRef.afterClosed().subscribe(async (changesMade) => {
      console.log('changesMade', changesMade);
      if (changesMade) {
        this.dialogRef.close();
      }
    });
  }
}
