// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
  border: 1px solid #bfc8cc;
  border-radius: 8px !important;
  padding: 24px 24px 8px 24px;
  box-shadow: none !important;
}

.header-row {
  display: flex;
  flex-direction: row;
}
.header-row .title {
  margin: 0 0 12px 0 !important;
}

.info-row {
  display: flex;
  flex-direction: row;
}
.info-row .info-child {
  flex: 1;
}

.part-icon {
  margin-right: 8px;
}`, "",{"version":3,"sources":["webpack://./src/app/planning/components/appointment-info-card/appointment-info-card.component.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,6BAAA;EACA,2BAAA;EACA,2BAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;AACF;AACE;EACE,6BAAA;AACJ;;AAGA;EACE,aAAA;EACA,mBAAA;AAAF;AAEE;EACE,OAAA;AAAJ;;AAIA;EACE,iBAAA;AADF","sourcesContent":[".card {\n  border: 1px solid #bfc8cc;\n  border-radius: 8px !important;\n  padding: 24px 24px 8px 24px;\n  box-shadow: none !important;\n}\n\n.header-row {\n  display: flex;\n  flex-direction: row;\n\n  .title {\n    margin: 0 0 12px 0 !important;\n  }\n}\n\n.info-row {\n  display: flex;\n  flex-direction: row;\n\n  .info-child {\n    flex: 1;\n  }\n}\n\n.part-icon {\n  margin-right: 8px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
