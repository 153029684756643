import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  setDoc,
} from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { db, functions } from 'src/app/app.component';
import { PlanningUserRights, TeamUserRights } from 'src/app/enums';
import { PlanningUser } from 'src/app/interfaces';
import { CustomValidators } from 'src/app/validators/custom-validators';

@Component({
  selector: 'app-manage-team-user',
  templateUrl: './manage-team-user.component.html',
  styleUrls: ['./manage-team-user.component.scss'],
})
export class ManageTeamUserComponent implements OnInit {
  user: PlanningUser;
  rights = { ...PlanningUserRights, ...TeamUserRights };
  appointmentTypes = [];
  particularities = [];
  additionalParticularities = [];
  distanceLabels = [];
  selectedRight = 'planner';
  townshipId: string;
  loaded = false;
  saving = false;
  userForm: UntypedFormGroup = this.fb.group({
    firstName: new UntypedFormControl(null, Validators.required),
    lastName: new UntypedFormControl(null, Validators.required),
    email: new UntypedFormControl(null, [
      Validators.required,
      CustomValidators.emailValidator,
    ]),
    phoneNumber: new UntypedFormControl(null, [
      Validators.required,
      CustomValidators.phoneValidator,
    ]),
    rights: new UntypedFormControl(null, [Validators.required]),
    address: this.fb.group({
      postal: new UntypedFormControl(null, [
        Validators.required,
        CustomValidators.postalValidator,
      ]),
      houseNumber: new UntypedFormControl(null, [Validators.required]),
      houseNumberAddition: new UntypedFormControl(''),
    }),
    workData: this.fb.group({
      minWeeklyHours: new UntypedFormControl(null, [
        Validators.required,
        Validators.min(0),
      ]),
      maxWeeklyHours: new UntypedFormControl(null, [
        Validators.required,
        Validators.min(0),
      ]),
      external: new UntypedFormControl(false),
      appointmentTypes: new UntypedFormControl([]),
      particularities: new UntypedFormControl([]),
      additionalParticularities: new UntypedFormControl([]),
      additionalParticularity: new UntypedFormControl(''),
      distanceLabels: new UntypedFormControl([]),
    }),
  });
  readonly separatorKeysCodes = [COMMA, ENTER] as const;

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private snackbar: MatSnackBar
  ) {}

  async ngOnInit(): Promise<void> {
    this.townshipId = localStorage.getItem('township') as string;
    const appointmentTypesDocs = await getDocs(
      query(
        collection(db, `township/${this.townshipId}/appointmentTypes`),
        orderBy('name')
      )
    );
    appointmentTypesDocs.forEach((doc) => {
      const data = doc.data();
      if (data.name) {
        this.appointmentTypes.push(data.name);
      }
    });
    const distanceLabelsDocs = await getDocs(
      query(
        collection(db, `township/${this.townshipId}/distanceLabels`),
        orderBy('rangeStart')
      )
    );
    distanceLabelsDocs.forEach((doc) => {
      const data = doc.data();
      if (data.name) {
        this.distanceLabels.push({ id: doc.id, ...data });
      }
    });
    const particularityDocs = await getDocs(
      query(
        collection(db, `township/${this.townshipId}/planningParticularities`)
      )
    );
    particularityDocs.forEach((doc) => {
      const data = doc.data();
      if (data.iconPath && data.iconPath != '') {
        this.particularities.push({ id: doc.id, ...data });
      }
    });

    const userId = this.route.snapshot.paramMap.get('id');
    if (userId && userId != 'new') {
      const ref = doc(
        db,
        `township/${this.townshipId}/planningUsers/${userId}`
      );
      this.user = { id: userId, ...(await getDoc(ref)).data() } as PlanningUser;
    }
    if (userId == 'new' || this.user?.rights == 'planner') {
      const groupControls = (<FormGroup>this.userForm.controls.workData)
        .controls;
      this.distanceLabels.forEach((label) => {
        groupControls.distanceLabels.value.push(label.id);
      });
    }
    if (this.user) {
      if (this.user.rights) {
        this.changedRights(this.user.rights);
      }
      this.userForm.patchValue(this.user);
      this.userForm.patchValue(this.user.workData);
      this.additionalParticularities =
        this.user.workData?.additionalParticularities ?? [];
    } else {
      this.userForm.addControl(
        'password',
        new FormControl(null, [Validators.required, Validators.minLength(6)])
      );
    }
    this.loaded = true;
  }

  changedRights(right) {
    this.selectedRight = right;
    if (right == 'coach' || right == 'handyman') {
      this.userForm.controls.workData.enable();
      this.userForm.controls.address.enable();
    } else {
      this.userForm.controls.workData.disable();
      this.userForm.controls.address.disable();
    }
  }

  selectOption(option: string, control: string) {
    const groupControls = (<FormGroup>this.userForm.controls.workData).controls;
    const index = groupControls[control].value.indexOf(option);
    if (index != -1) {
      groupControls[control].value.splice(index, 1);
    } else {
      groupControls[control].value.push(option);
    }
  }

  isSelected(option: string, control: string) {
    const groupControls = (<FormGroup>this.userForm.controls.workData).controls;
    return groupControls[control].value.some((element) => element == option);
  }

  handleAdditionalParticularities(particularity: any, action: string) {
    console.log(particularity);
    const groupControls = (<FormGroup>this.userForm.controls.workData).controls;
    const index = groupControls.additionalParticularities.value.indexOf(
      particularity.value
    );
    if (action == 'add') {
      if (index == -1) {
        groupControls.additionalParticularities.value.push(particularity.value);
        particularity.chipInput!.clear();
      } else {
        this.snackbar.open(
          'Er bestaat al een bijzonderheid met deze naam',
          'X',
          {
            duration: 5000,
          }
        );
        return;
      }
    } else if (action == 'remove') {
      groupControls.additionalParticularities.value.splice(index, 1);
    }
    this.additionalParticularities =
      groupControls.additionalParticularities.value;
    console.log(this.additionalParticularities);
  }

  async save() {
    if (this.saving) {
      return;
    }
    this.userForm.markAllAsTouched();
    if (!this.userForm.valid) {
      return;
    }
    const saveObj = this.userForm.value;
    if (saveObj.rights == 'planner') {
      saveObj.workData = {};
    }
    if (saveObj.workData?.minWeeklyHours >= saveObj.workData?.maxWeeklyHours) {
      this.snackbar.open(
        'Het maximaal aantal uur per week moet hoger zijn dan het minimaal aantal uur',
        'X',
        {
          duration: 5000,
        }
      );
      return;
    }
    this.saving = true;
    let userId = this.user?.id;
    if (!this.user) {
      try {
        const callable = httpsCallable(functions, 'accountCreatePlanningUser');
        const result = await callable({
          townshipId: this.townshipId,
          email: saveObj.email,
          password: saveObj.password,
        });
        if (result.data['status'] != 'done') {
          let message: string;
          if (result.data['status'] == 'email_already_exists') {
            message = 'Er bestaat al een account met dit e-mailadres';
          } else if (result.data['status'] == 'unknown_error_creating') {
            message = 'Het aanmaken van een nieuwe gebruiker is mislukt';
          }
          this.snackbar.open(message, 'X', {
            duration: 5000,
          });
          this.saving = false;
          return;
        }
        userId = result.data['userId'];
      } catch (e) {
        console.log(e);
        this.snackbar.open('Er is iets misgegaan', 'X', {
          duration: 5000,
        });
        this.saving = false;
        return;
      }
    }
    console.log(saveObj);
    if (
      saveObj.workData?.additionalParticularities &&
      saveObj.workData?.additionalParticularity != ''
    ) {
      saveObj.workData.additionalParticularities.push(
        saveObj.workData.additionalParticularity
      );
    }
    delete saveObj.workData['additionalParticularity'];
    delete saveObj['password'];
    const docRef = doc(
      db,
      `/township/${this.townshipId}/planningUsers/${userId}`
    );
    try {
      await setDoc(docRef, saveObj, { merge: true });
      this.router.navigate(['users']);
    } catch (error) {
      console.log(error);
      this.snackbar.open(
        'Er is iets fout gegaan met het opslaan van de gegevens',
        'X',
        {
          duration: 5000,
        }
      );
      this.saving = false;
      return;
    }
  }

  getError(control, group = null) {
    const field = group
      ? (<FormGroup>this.userForm.controls[group]).get(control)
      : this.userForm.get(control);
    if (field.touched || !field.pristine) {
      let error: string;
      if (field.hasError('required')) {
        error = 'Dit veld is verplicht';
      }
      if (field.hasError('min')) {
        error = 'Dit waarde van dit veld mag niet lager dan 0 zijn';
      }
      if (field.hasError('emailValidator')) {
        error = 'Het ingevulde emailadres is ongeldig';
      }
      if (field.hasError('phoneValidator')) {
        error = 'Het ingevulde telefoonnummer is ongeldig';
      }
      if (field.hasError('postalValidator')) {
        error = 'De ingevulde postcode is ongeldig';
      }
      if (field.hasError('minlength')) {
        error = 'Het wachtwoord moet minstens 6 tekens bevatten';
      }
      return error;
    }
    return '';
  }
}
