import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';
import { Location } from '@angular/common';
import { initializeApp } from 'firebase/app';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {
  Organisation,
  PlanningUser,
  Township,
  TownshipUser,
  User,
} from './interfaces';
import {
  ManagementRights,
  PlanningUserRights,
  TownshipUserRights,
} from './enums';
import { Observable } from 'rxjs';

export const app = initializeApp(environment.firebase);
export const db = getFirestore(app);
export const functions = getFunctions(app, 'europe-west1');
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public pageTitle: string;
  showNavbar: boolean = false;
  env = environment;
  sideNavRoutes = [];
  isSuperUser: boolean = false;
  townships: any[];
  isMemberOfMultipleOrgs: boolean = false;
  township: any;
  townshipName: any;
  organisation: Organisation;
  avatarUrl: string;
  townshipUser: Observable<TownshipUser>;
  isOwner: boolean = false;
  isAdministrator: boolean = false;
  isHelpdeskUser: boolean = false;
  isFinancialUser: boolean = false;
  isControllerUser: boolean = false;
  isStatisticsUser: boolean = false;
  userEmail: string;

  constructor(
    private titleService: Title,
    public router: Router,
    private location: Location,
    public afAuth: AngularFireAuth,
    public afs: AngularFirestore
  ) {
    this.titleService.setTitle(environment.name);
    this.router.events.subscribe((event) => {
      if (
        this.location.path().includes('/statistics/widget/') ||
        this.location.path().includes('/email')
      ) {
        this.showNavbar = false;
      } else {
        this.showNavbar = true;
      }
    });
  }
  ngOnInit(): void {
    if (this.env.env === 'planning') {
      this.sideNavRoutes = [
        {
          name: 'Planning',
          route: '/planning',
          icon: 'today',
        },
        {
          name: 'Dagdelen',
          route: '/day-parts',
          icon: 'calendar_view_day',
        },
        {
          name: 'Afspraaktypen',
          route: '/appointment-types',
          icon: 'event_note',
        },
        {
          name: 'Team',
          route: '/users',
          icon: 'groups',
        },
        {
          name: 'Instellingen',
          route: '/planner-settings',
          icon: 'settings',
        },
      ];

      this.afAuth.user.subscribe((user) => {
        if (user) {
          this.userEmail = user.email;
          const userDoc = this.afs.doc<User>('users/' + user.uid);
          const userId = user.uid;
          const userDocData = userDoc.valueChanges();
          userDocData.subscribe(async (res) => {
            const userData = res;
            if (userData.rights === 'admin') {
              this.isSuperUser = true;
            }
            if (userData.orgRights && userData.orgRights.length > 1) {
              this.townships = [];
              for (const right of userData.orgRights) {
                const township = (
                  await getDoc(
                    doc(this.afs.firestore, `township/${right.townshipId}`)
                  )
                ).data() as Township;
                township.id = right.townshipId;

                if (township && township.name) {
                  this.townships.push(township);
                }
              }
              this.isMemberOfMultipleOrgs = true;
            }
            if (userData.township) {
              const townshipDoc = this.afs.doc<Township>(
                `township/${userData.township}`
              );
              this.township = townshipDoc.valueChanges();
              this.township.subscribe(async (value) => {
                this.township = value;
                this.townshipName = this.township.name;
                if (userData.organisation && this.env.env === 'org') {
                  this.organisation = (
                    await getDoc(
                      doc(
                        this.afs.firestore,
                        `township/${userData.township}/organisations/${userData.organisation}`
                      )
                    )
                  ).data() as Organisation;
                  if (this.organisation.useHeaderImage) {
                    this.avatarUrl = this.organisation.headerImageUrl;
                  }
                }
                if (this.env.env === 'township' && userData.township) {
                  if (this.township?.useLogoImage) {
                    this.avatarUrl = this.township.logoImageUrl;
                  }
                }
              });
              if (this.env.env === 'township') {
                const userDataTownship = (
                  await getDoc(
                    doc(
                      this.afs.firestore,
                      `township/${userData.township}/users/${user.uid}`
                    )
                  )
                ).data() as TownshipUser;
                const allowedRights = [];
                for (var key of Object.keys({
                  ...ManagementRights,
                  ...TownshipUserRights,
                })) {
                  allowedRights.push(key);
                }

                if (
                  (userData.rights !== 'admin' &&
                    !allowedRights.find(
                      (key) => key === userDataTownship.rights
                    )) ||
                  userData.organisation
                ) {
                  // Sign user out if not a township environment user
                  this.logout();
                }
              }
              if (this.env.env === 'planning') {
                const userDataTownship = (
                  await getDoc(
                    doc(
                      this.afs.firestore,
                      `township/${userData.township}/users/${user.uid}`
                    )
                  )
                ).data() as TownshipUser;
                const userDataPlanning = (
                  await getDoc(
                    doc(
                      this.afs.firestore,
                      `township/${userData.township}/planningUsers/${user.uid}`
                    )
                  )
                ).data() as PlanningUser;
                const allowedRightsTownship = [];
                for (var key of Object.keys({
                  ...ManagementRights,
                })) {
                  allowedRightsTownship.push(key);
                }
                const allowedRightsPlanning = [];
                for (var key of Object.keys({
                  ...PlanningUserRights,
                })) {
                  allowedRightsPlanning.push(key);
                }
                if (
                  !(
                    this.isSuperUser ||
                    ((allowedRightsTownship.find(
                      (key) => key === userDataTownship.rights
                    ) ||
                      allowedRightsPlanning.find(
                        (key) => key === userDataPlanning.rights
                      )) &&
                      this.township.usesPlanningEnvironment &&
                      !userData.organisation)
                  )
                ) {
                  // Sign out user if not a planning environment user
                  this.logout();
                }
              }
              if (this.env.env === 'org') {
                if (!userData.organisation) {
                  // Sign user out if not an organisation environment user
                  this.logout();
                }
              }
              this.sideNavRoutes.forEach((element) => {
                if (this.router.url.includes(element.route)) {
                  this.sideNavRoutes[
                    this.sideNavRoutes.indexOf(element)
                  ].active = true;
                }
              });
            }
            if (userData.township) {
              const townshipUser = this.afs.doc<TownshipUser>(
                `township/${userData.township}/users/${userId}`
              );
              this.townshipUser = townshipUser.valueChanges();
              this.townshipUser.subscribe(async (value) => {
                if (!value) {
                  return;
                }
                switch (value.rights) {
                  case 'owner':
                    this.isOwner = true;
                  case 'admin':
                    this.isAdministrator = true;
                  case 'helpdesk':
                    this.isHelpdeskUser = true;
                    break;
                  case 'financial':
                    this.isFinancialUser = true;
                    break;
                  case 'controller':
                    this.isControllerUser = true;
                    break;
                  case 'statistics':
                    this.isStatisticsUser = true;
                    break;
                }
              });
            }
          });
        }
      });
    }
  }

  openLink() {
    let url = 'https://dev.beheer.lokalebon.nl';
    if (this.router.url.match(/^planning$/)) {
      url = this.router.url.replace('planning', 'beheer');
    }
    window.open(url, '_blank');
  }

  async logout() {
    localStorage.setItem('logoutRF', '1');
    await this.afAuth.signOut();
    if (localStorage.getItem('township')) {
      localStorage.removeItem('township');
    }
    if (localStorage.getItem('organisationId')) {
      localStorage.removeItem('organisationId');
    }
    return location.reload();
  }
}
