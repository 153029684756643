import { Component, OnInit } from '@angular/core';
import {
  getDocs,
  collection,
  query,
  orderBy,
  doc,
  updateDoc,
  setDoc,
  getDoc,
  deleteDoc,
} from 'firebase/firestore';
import { db } from '../app.component';
import {
  FormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomValidators } from '../validators/custom-validators';
import { MatDialog } from '@angular/material/dialog';
import { ParticularityDeleteDialogComponent } from './dialog/delete-particularity-dialog/particularity-delete-dialog.component';
import { getAuth } from 'firebase/auth';
import { DistanceLabel, User } from '../interfaces';
import { deleteObject, getStorage, ref } from 'firebase/storage';

@Component({
  selector: 'app-planning-settings',
  templateUrl: './planning-settings.component.html',
  styleUrls: ['./planning-settings.component.scss'],
})
export class PlanningSettingsComponent implements OnInit {
  townshipId: string = localStorage.getItem('township');
  // canvas: CanvasRenderingContext2D;
  distanceLabels: DistanceLabel[] = [];
  particularities: any = [];
  loaded: boolean = false;
  saving: boolean = false;
  isSuperUser: boolean = false;
  tabIndex: number = 0;
  labelForm: UntypedFormGroup = this.fb.group({});
  linksForm: UntypedFormGroup = this.fb.group({
    statisticsToolUrl: new UntypedFormControl(null),
    phoneNumber: new UntypedFormControl(null, CustomValidators.phoneValidator),
  });

  constructor(
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}

  async ngOnInit(): Promise<void> {
    // this.canvas = document.createElement('canvas').getContext('2d');
    if (history.state.tabIndex) {
      this.tabIndex = history.state.tabIndex;
      delete history.state.tabIndex;
    }
    const auth = getAuth();
    if (auth.currentUser) {
      const userDoc = doc(db, `users/${auth.currentUser.uid}`);
      const user = (await getDoc(userDoc)).data() as User;
      if (user.rights === 'admin') {
        this.isSuperUser = true;
      }
    }

    const distanceLabelsDocs = await getDocs(
      query(
        collection(db, `township/${this.townshipId}/distanceLabels`),
        orderBy('rangeStart')
      )
    );
    distanceLabelsDocs.forEach((doc) => {
      const data = doc.data();
      this.distanceLabels.push({ id: doc.id, ...data } as DistanceLabel);
      this.labelForm.addControl(doc.id, this.fb.group({}));
      const group = this.labelForm.controls[doc.id] as FormGroup;
      if (data.rangeStart) {
        group.addControl(
          'rangeStart',
          this.fb.control(data.rangeStart, Validators.required)
        );
      }
      if (data.rangeEnd) {
        group.addControl(
          'rangeEnd',
          this.fb.control(data.rangeEnd, Validators.required)
        );
      }
    });
    const particularityDocs = await getDocs(
      query(
        collection(db, `township/${this.townshipId}/planningParticularities`),
        orderBy('default', 'desc')
      )
    );
    particularityDocs.forEach((doc) => {
      const data = doc.data();
      if (data.iconPath && data.iconPath != '') {
        this.particularities.push({ id: doc.id, ...data });
      }
    });

    const links = (
      await getDoc(doc(db, `township/${this.townshipId}/settings/planning`))
    ).data();
    this.linksForm.patchValue(links);
    this.loaded = true;
  }

  async saveLinks() {
    if (this.saving) {
      return;
    }
    this.labelForm.markAllAsTouched();
    if (!this.labelForm.valid) {
      return;
    }
    this.saving = true;
    const docRef = doc(db, `/township/${this.townshipId}/settings/planning`);
    await setDoc(docRef, this.linksForm.value, { merge: true });
    this.saving = false;
  }

  async saveDistanceLabels() {
    if (this.saving) {
      return;
    }
    this.labelForm.markAllAsTouched();
    if (!this.labelForm.valid) {
      return;
    }
    let message: string;
    for (let i = 0; i < this.distanceLabels.length; i++) {
      const control = (
        this.labelForm.controls[this.distanceLabels[i]['id']] as FormGroup
      ).controls;
      // check if rangeStart of current label is not above rangeEnd of previous label (only checked if not the first label in the array)
      if (i != 0) {
        const compareControl = (
          this.labelForm.controls[this.distanceLabels[i - 1]['id']] as FormGroup
        ).controls;
        if (control.rangeStart.value < compareControl.rangeEnd.value) {
          message =
            'De "Van" of "vanaf" waarde van een afstandslabel mag niet lager zijn dan de "Tot" waarde van het vorige afstandslabel';
          break;
        }
      } else {
        // check if rangeEnd of current label is not below rangeStart of next label (only checked for the first label in the array)
        const compareControl = (
          this.labelForm.controls[this.distanceLabels[i + 1]['id']] as FormGroup
        ).controls;
        if (control.rangeEnd.value > compareControl.rangeStart.value) {
          message =
            'De "Minder dan" waarde van een afstandslabel mag niet hoger zijn dan de "Van" waarde van het volgende afstandslabel';
          break;
        }
      }
      //check if rangeStart of current label is not equal or above to rangeEnd of current label (checked for all labels except the first and last in the array)
      if (i != 0 && i + 1 != this.distanceLabels.length) {
        if (control.rangeStart.value >= control.rangeEnd.value) {
          message =
            'De "Van" waarde van een afstandslabel mag niet hoger of gelijk zijn aan de bijbehorende "Tot" waarde';
          break;
        }
      }
    }
    if (message) {
      this.snackBar.open(message, 'X', {
        duration: 5000,
      });
      return;
    }
    this.saving = true;
    const promises = [];
    this.distanceLabels.forEach((label) => {
      const values = (this.labelForm.controls[label.id] as FormGroup).value;
      const docRef = doc(
        db,
        `/township/${this.townshipId}/distanceLabels/${label.id}`
      );
      promises.push(updateDoc(docRef, values));
    });
    await Promise.all(promises);
    this.saving = false;
  }

  deleteParticularity(particularity) {
    if (!particularity.default) {
      const dialogRef = this.dialog.open(ParticularityDeleteDialogComponent, {
        width: '400px',
        autoFocus: false,
      });
      dialogRef.afterClosed().subscribe(async (result) => {
        if (result) {
          const docRef = `township/${this.townshipId}/planningParticularities/${particularity.id}`;
          const data = (await getDoc(doc(db, docRef))).data();
          const iconRef = ref(getStorage(), data.iconPath);
          try {
            deleteObject(iconRef);
          } catch (error) {
            console.log(error);
          }
          await deleteDoc(doc(db, docRef));
        }
      });
    }
  }

  // trailingKm(e) {
  //   const trailElement = e.target.nextSibling;
  //   if (e.target.value != '') {
  //     this.canvas.font = `${getComputedStyle(e.target).fontSize} ${
  //       getComputedStyle(e.target).fontFamily
  //     }`;
  //     const width = this.canvas.measureText(e.target.value).width;
  //     if (width + 50 <= parseFloat(getComputedStyle(e.target).width)) {
  //       trailElement.style.left = (width + 8) * 1.05 + 'px';
  //       trailElement.style.display = 'inline-block';
  //       return;
  //     }
  //   }
  //   trailElement.style.display = 'none';
  // }

  getError(control, form, group = null) {
    const field = group
      ? (<FormGroup>form.controls[group]).get(control)
      : form.get(control);
    if (field.touched || !field.pristine) {
      let error: string;
      if (field.hasError('required')) {
        error = 'Dit veld is verplicht';
      }
      if (field.hasError('phoneValidator')) {
        error = 'Het ingevulde telefoonnummer is ongeldig';
      }
      return error;
    }
    return '';
  }
}
