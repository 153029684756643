import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import {
  doc,
  setDoc,
  getDoc,
  query,
  collection,
  orderBy,
  onSnapshot,
} from 'firebase/firestore';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../../environments/environment';
import {
  Organisation,
  PlanningUser,
  Township,
  TownshipUser,
  User,
} from '../interfaces';

import { SendVoucherComponent } from '../voucher-management/send-voucher/send-voucher.component';
import { ManageVoucherComponent } from '../voucher-management/manage-voucher/manage-voucher.component';
import { UpgradeDialogComponent } from './dialogs/upgrade-dialog/upgrade-dialog.component';
import { ChangeTownshipDialogComponent } from '../dashboard-township/dialog/change-township-dialog/change-township-dialog.component';
import {
  ManagementRights,
  PlanningUserRights,
  TownshipUserRights,
} from '../enums';
import { Router } from '@angular/router';
import { db } from '../app.component';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnInit {
  env = environment;
  userDoc: AngularFirestoreDocument<User>;
  userId: string;
  user: Observable<User>;
  userData: User;
  townshipDoc: AngularFirestoreDocument<Township>;
  townshipUser: AngularFirestoreDocument<TownshipUser>;
  $townshipUser: Observable<TownshipUser>;
  township: Township;
  organisation: Organisation; // Only filled if in organisation environment
  fixed = true;
  userEmail: string;
  avatarUrl: string;
  isSuperUser: boolean = false;
  townshipName: string;
  townships: Township[] = [];
  townshipId = localStorage.getItem('township');
  isMemberOfMultipleOrgs: boolean = false;
  isHelpdeskUser: boolean = false;
  isFinancialUser: boolean = false;
  isControllerUser: boolean = false;
  isStatisticsUser: boolean = false;
  sideNavRoutes = [];
  isOwner: boolean = false;
  isAdministrator: boolean = false;
  particularities: any = [];

  constructor(
    private router: Router,
    public afAuth: AngularFireAuth,
    public afs: AngularFirestore,
    public dialog: MatDialog,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.afAuth.user.subscribe((user) => {
      if (user) {
        this.userEmail = user.email;
        this.userDoc = this.afs.doc<User>('users/' + user.uid);
        this.userId = user.uid;
        this.user = this.userDoc.valueChanges();
        this.user.subscribe(async (res) => {
          this.userData = res;
          if (res.township) {
            localStorage.setItem('township', res.township);
          }
          if (res.organisation) {
            localStorage.setItem('organisationId', res.organisation);
          }
          if (this.userData.rights === 'admin') {
            this.isSuperUser = true;
          }
          if (this.userData.orgRights && this.userData.orgRights.length > 1) {
            this.townships = [];
            for (const right of this.userData.orgRights) {
              const township = (
                await getDoc(
                  doc(this.afs.firestore, `township/${right.townshipId}`)
                )
              ).data() as Township;
              township.id = right.townshipId;

              if (township && township.name) {
                this.townships.push(township);
              }
            }
            this.isMemberOfMultipleOrgs = true;
          }
          if (this.userData.township) {
            this.townshipDoc = this.afs.doc<Township>(
              `township/${this.userData.township}`
            );
            const townshipData = this.townshipDoc.valueChanges();
            townshipData.subscribe(async (value) => {
              this.township = value;
              this.townshipName = this.township.name;
              if (this.userData.organisation && this.env.env === 'org') {
                this.organisation = (
                  await getDoc(
                    doc(
                      this.afs.firestore,
                      `township/${this.userData.township}/organisations/${this.userData.organisation}`
                    )
                  )
                ).data() as Organisation;
                if (this.organisation.useHeaderImage) {
                  this.avatarUrl = this.organisation.headerImageUrl;
                }
              }
              if (this.env.env === 'township' && this.userData.township) {
                if (this.township?.useLogoImage) {
                  this.avatarUrl = this.township.logoImageUrl;
                }
              }
            });
            if (this.env.env === 'township') {
              const userDataTownship = (
                await getDoc(
                  doc(
                    this.afs.firestore,
                    `township/${this.userData.township}/users/${user.uid}`
                  )
                )
              ).data() as TownshipUser;
              const allowedRights = [];
              for (var key of Object.keys({
                ...ManagementRights,
                ...TownshipUserRights,
              })) {
                allowedRights.push(key);
              }

              if (
                (this.userData.rights !== 'admin' &&
                  !allowedRights.find(
                    (key) => key === userDataTownship.rights
                  )) ||
                this.userData.organisation
              ) {
                // Sign user out if not a township environment user
                this.logout();
              }
            }
            if (this.env.env === 'planning') {
              const userDataTownship = (
                await getDoc(
                  doc(
                    this.afs.firestore,
                    `township/${this.userData.township}/users/${user.uid}`
                  )
                )
              ).data() as TownshipUser;
              const userDataPlanning = (
                await getDoc(
                  doc(
                    this.afs.firestore,
                    `township/${this.userData.township}/planningUsers/${user.uid}`
                  )
                )
              ).data() as PlanningUser;
              const allowedRightsTownship = [];
              for (var key of Object.keys({
                ...ManagementRights,
              })) {
                allowedRightsTownship.push(key);
              }
              const allowedRightsPlanning = [];
              for (var key of Object.keys({
                ...PlanningUserRights,
              })) {
                allowedRightsPlanning.push(key);
              }
              if (
                !(
                  this.isSuperUser ||
                  ((allowedRightsTownship.find(
                    (key) => key === userDataTownship.rights
                  ) ||
                    allowedRightsPlanning.find(
                      (key) => key === userDataPlanning.rights
                    )) &&
                    this.township.usesPlanningEnvironment &&
                    !this.userData.organisation)
                )
              ) {
                // Sign out user if not a planning environment user
                this.logout();
              }
              this.sideNavRoutes = [
                {
                  name: 'Planning',
                  route: '/planning',
                  icon: 'today',
                  active: false,
                },
                {
                  name: 'Dagdelen',
                  route: '/day-parts',
                  icon: 'calendar_view_day',
                  active: false,
                },
                {
                  name: 'Afspraaktypen',
                  route: '/appointment-types',
                  icon: 'event_note',
                  active: false,
                },
                {
                  name: 'Team',
                  route: '/users',
                  icon: 'groups',
                  active: false,
                },
                {
                  name: 'Instellingen',
                  route: '/planner-settings',
                  icon: 'settings',
                  active: false,
                },
              ];
            }
            if (this.env.env === 'org') {
              if (!this.userData.organisation) {
                // Sign user out if not an organisation environment user
                this.logout();
              }
            }
            this.sideNavRoutes.forEach((element) => {
              if (this.router.url.includes(element.route)) {
                this.sideNavRoutes[this.sideNavRoutes.indexOf(element)].active =
                  true;
              }
            });
          }
          if (this.userData.township) {
            this.townshipUser = this.afs.doc<TownshipUser>(
              `township/${this.userData.township}/users/${this.userId}`
            );
            this.$townshipUser = this.townshipUser.valueChanges();
            this.$townshipUser.subscribe(async (value) => {
              if (!value) {
                return;
              }
              switch (value.rights) {
                case 'owner':
                  this.isOwner = true;
                case 'admin':
                  this.isAdministrator = true;
                case 'helpdesk':
                  this.isHelpdeskUser = true;
                  break;
                case 'financial':
                  this.isFinancialUser = true;
                  break;
                case 'controller':
                  this.isControllerUser = true;
                  break;
                case 'statistics':
                  this.isStatisticsUser = true;
                  break;
              }
            });
          }
        });
      }
    });
    const particularitiesQuery = query(
      collection(db, `township/${this.townshipId}/planningParticularities`),
      orderBy('default', 'desc')
    );
    onSnapshot(particularitiesQuery, (querySnapshot) => {
      this.particularities = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        this.particularities.push({ id: doc.id, ...data });
        this.iconRegistry.addSvgIcon(
          doc.id,
          this.sanitizer.bypassSecurityTrustResourceUrl(data.iconPath)
        );
      });
    });
  }

  async logout() {
    localStorage.setItem('logoutRF', '1');
    await this.afAuth.signOut();
    if (localStorage.getItem('township')) {
      localStorage.removeItem('township');
    }
    if (localStorage.getItem('organisationId')) {
      localStorage.removeItem('organisationId');
    }
    return location.reload();
  }

  async openSendVoucherDialog() {
    if (!this.userData) {
      return; // err
    }
    this.dialog.open(SendVoucherComponent, {
      width: '450px',
      autoFocus: false,
    });
  }
  openEditVoucherDialog(type: string) {
    this.dialog.open(ManageVoucherComponent, {
      width: '450px',
      data: { type },
    });
  }

  showUpdate() {
    const dialogRef = this.dialog.open(UpgradeDialogComponent, {
      width: '500px',
    });
    dialogRef.afterClosed().subscribe(() => {});
  }

  changeTownship(userId: string) {
    const dialogRef = this.dialog.open(ChangeTownshipDialogComponent, {
      width: '650px',
    });
    dialogRef.afterClosed().subscribe(async (townshipId) => {
      if (townshipId) {
        localStorage.setItem('township', townshipId);
        let data: any = { township: townshipId };
        if (this.userData.orgRights) {
          const org = this.userData.orgRights.find((org) => {
            return org.townshipId === townshipId;
          });
          if (org.organisationId) {
            data = { ...data, organisation: org.organisationId };
          }
        }
        this.townshipDoc = this.afs.doc<Township>('township/' + townshipId);
        await setDoc(doc(this.afs.firestore, `users/${userId}`), data, {
          merge: true,
        });

        window.location.reload();
      }
    });
  }

  openLink() {
    let url = 'https://dev.beheer.lokalebon.nl';
    if (this.router.url.match(/^planning$/)) {
      url = this.router.url.replace('planning', 'beheer');
    }
    window.open(url, '_blank');
  }

  async setTownship(township: Township) {
    let rightsObj = this.userData.orgRights.find(
      (o) => o.townshipId === township.id
    );

    localStorage.setItem('township', rightsObj.townshipId);
    const dataObj =
      this.env.env === 'org'
        ? {
            organisation: rightsObj.organisationId,
            township: rightsObj.townshipId,
          }
        : {
            township: rightsObj.townshipId,
          };

    await setDoc(doc(this.afs.firestore, `users/${this.userId}`), dataObj, {
      merge: true,
    });
    this.townships = [];
    this.township = null;
    window.location.reload();
  }
}
