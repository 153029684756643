// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-items {
  width: 85%;
  margin: 0 auto 0 auto;
}
.list-items .list-item-wrapper {
  border-bottom: 1px solid black;
}
.list-items .list-item-wrapper .list-item-col-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.list-items .list-item-wrapper .list-item-col-wrapper .list-item-col {
  flex: 5;
  align-content: center;
  min-height: 50px;
}
.list-items .list-item-wrapper .list-item-col-wrapper .list-item-col:last-child {
  flex: 1;
}
.list-items .list-item-wrapper .list-item-col-wrapper .list-item-buttons {
  display: flex;
  justify-content: flex-end;
}
.list-items .headers {
  border-bottom: 0;
  font-weight: bold;
  margin: 0 0 20px 0;
}`, "",{"version":3,"sources":["webpack://./src/app/appointment-types/appointment-types.component.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,qBAAA;AACF;AAAE;EACE,8BAAA;AAEJ;AAAI;EACE,aAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;AAEN;AADM;EACE,OAAA;EACA,qBAAA;EACA,gBAAA;AAGR;AADM;EACE,OAAA;AAGR;AADM;EACE,aAAA;EACA,yBAAA;AAGR;AAEE;EACE,gBAAA;EACA,iBAAA;EACA,kBAAA;AAAJ","sourcesContent":[".list-items {\n  width: 85%;\n  margin: 0 auto 0 auto;\n  .list-item-wrapper {\n    border-bottom: 1px solid black;\n\n    .list-item-col-wrapper {\n      display: flex;\n      width: 100%;\n      height: 100%;\n      overflow: hidden;\n      .list-item-col {\n        flex: 5;\n        align-content: center;\n        min-height: 50px;\n      }\n      .list-item-col:last-child {\n        flex: 1;\n      }\n      .list-item-buttons {\n        display: flex;\n        justify-content: flex-end;\n      }\n    }\n  }\n\n  .headers {\n    border-bottom: 0;\n    font-weight: bold;\n    margin: 0 0 20px 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
