import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  FormGroupDirective,
  NgForm,
  Validators,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

import { environment } from '../../../environments/environment';
import {
  doc,
  DocumentData,
  DocumentReference,
  getDoc,
  updateDoc,
} from 'firebase/firestore';
import { User } from 'src/app/interfaces';
import {
  getAuth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  sendEmailVerification,
  getMultiFactorResolver,
  RecaptchaVerifier,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  MultiFactorResolver,
  Auth,
} from 'firebase/auth';
import { db } from 'src/app/app.component';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: UntypedFormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  // video: string = environment.login.video;
  userDoc: DocumentReference<DocumentData>;
  env = environment;
  image: string = environment.login.image;
  name: string = environment.name;
  prefix: string = environment.phone.nl;
  loginMethodEmail = environment.loginMethod.email;
  title = environment.login.title.login;
  description = environment.login.description.login;
  environmentDB: string = environment.stepper.environmentDB;
  envName: string;
  // environment = environment.env;

  email: string;
  password: string;
  loginForm: UntypedFormGroup;
  loading: boolean;
  showPassword: boolean;
  smsRequired: boolean = false;
  verificationId: string;
  resolver: MultiFactorResolver;
  auth: Auth;

  // emailFormControl = new FormControl('', [
  //   Validators.required,
  //   Validators.email,
  // ]);
  matcher = new MyErrorStateMatcher();
  errorLoginMessage: string;
  user: any;
  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private snackbar: MatSnackBar
  ) {}
  ngOnInit() {
    if (localStorage.getItem('logoutRF')) {
      localStorage.removeItem('logoutRF');
      this.snackbar.open(
        'Je account heeft geen rechten in deze omgeving.',
        'X',
        {
          duration: 5000,
        }
      );
    }
    this.loginForm = this.fb.group({
      email: [, Validators.required],
      password: [, [Validators.required]],
    });
    this.auth = getAuth();
  }

  // Login with Email
  async mailLogin() {
    if (this.loginForm.invalid) {
      return this.loginForm.markAllAsTouched();
    }
    if (this.loginForm.valid && !this.loading && !this.smsRequired) {
      this.loading = true;
      signInWithEmailAndPassword(
        this.auth,
        this.loginForm.value.email,
        this.loginForm.value.password
      )
        .then(async (userCredential) => {
          const user = userCredential.user;
          console.log('user', user);
          if (user) {
            if (!user.emailVerified) {
              await sendEmailVerification(user);
              await this.auth.signOut();
              this.snackbar.open(
                'Je moet eerst je e-mailadres verifieren voordat je kunt inloggen, er wordt nu een verificatiemail gestuurd naar dit mailadres.',
                'X',
                {
                  duration: 5000,
                }
              );
              return location.reload();
            }
            const globalUserRef = doc(db, `users/${user.uid}`);
            const globalUserDoc = (await getDoc(globalUserRef)).data() as User;
            await updateDoc(globalUserRef, {
              lastLoginDate: new Date(),
            });
            if (
              globalUserDoc.tutorial &&
              globalUserDoc.tutorial[this.environmentDB] === true
            ) {
              this.router.navigate([this.env.defaultRoute.path]);
            } else {
              this.router.navigate(['/first-time']);
            }
          }
        })
        .catch(async (error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          this.loading = false;
          // handle SMS-multifactor
          if (errorCode == 'auth/multi-factor-auth-required') {
            try {
              this.smsRequired = true;
              this.title = 'Verificatiecode verstuurd';
              this.description =
                'Vul de verificatiecode in die is verstuurd naar';
              this.loginForm.addControl('smsCode', new UntypedFormControl());
              this.resolver = getMultiFactorResolver(this.auth, error);
              const recaptchaVerifier = new RecaptchaVerifier(
                'login-button',
                {
                  'size': 'invisible',
                },
                this.auth
              );
              const phoneInfoOptions = {
                multiFactorHint: this.resolver.hints[0],
                session: this.resolver.session,
              };
              const phoneAuthProvider = new PhoneAuthProvider(this.auth);
              this.verificationId = await phoneAuthProvider.verifyPhoneNumber(
                phoneInfoOptions,
                recaptchaVerifier
              );
            } catch (error) {
              console.log('ERROR:', error);
            }
          } else {
            this.loginForm.controls.password.setErrors({
              invalidCredentials: true,
            });
            console.log(errorCode, errorMessage);
          }
        });
    } else if (this.smsRequired) {
      const cred = PhoneAuthProvider.credential(
        this.verificationId,
        this.loginForm.value.smsCode
      );
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
      const userCredential = await this.resolver.resolveSignIn(
        multiFactorAssertion
      );
      const globalUserRef = doc(db, `users/${userCredential.user.uid}`);
      const globalUserDoc = (await getDoc(globalUserRef)).data() as User;
      await updateDoc(globalUserRef, {
        lastLoginDate: new Date(),
      });
      if (
        globalUserDoc.tutorial &&
        globalUserDoc.tutorial[this.environmentDB] === true
      ) {
        this.router.navigate([this.env.defaultRoute.path]);
      } else {
        this.router.navigate(['/first-time']);
      }
    }
  }

  cancelVerificationProcessSms() {
    this.smsRequired = false;
  }

  async resetPassword() {
    if (!this.loginForm.value.email) {
      this.loginForm.controls.email.setErrors({
        invalidEmail: true,
      });

      return this.loginForm.controls.email.markAsTouched();
    }
    try {
      await sendPasswordResetEmail(this.auth, this.loginForm.value.email);
      this.errorLoginMessage = '';
      this.snackbar.open(
        'Er wordt een wachtwoord reset link verstuurd indien er een account bekend is op het mailadres',
        'X',
        {
          duration: 5000,
        }
      );
    } catch (err) {
      this.errorLoginMessage =
        'Er is iets fout gegaan, probeer het later opnieuw.';
    }
  }
  getError(name) {
    const field = this.loginForm.get(name);
    if (field.touched || !field.pristine) {
      if (field.hasError('required')) {
        return 'Dit veld moet ingevuld zijn.';
      }
    }
    if (field.hasError('invalidCredentials')) {
      return 'De combinatie e-mail en wachtwoord is niet correct.';
    }

    if (field.hasError('invalidEmail')) {
      return 'Voer het mailadres van een account in om het wachtwoord te resetten.';
    }
    return;
  }
}
