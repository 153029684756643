import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { getAuth } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { db } from './app.component';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class permissionGuard {
  constructor(private router: Router) {}
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const allowedRights = route.data.permissions ?? [];
    const auth = getAuth();
    const userId = auth.currentUser?.uid;
    if (userId == null) {
      this.router.navigate([environment.defaultRoute.id]);
      return false;
    }

    const rootUser = (await getDoc(doc(db, `users/${userId}`))).data();
    if (rootUser) {
      if (rootUser.rights && rootUser.rights == 'admin') {
        return true;
      }

      const townshipId = rootUser.township;
      let user = null;
      if (environment.env == 'org') {
        const orgId = rootUser.organisation;
        user = (
          await getDoc(
            doc(
              db,
              `township/${townshipId}/organisations/${orgId}/users/${userId}`
            )
          )
        ).data();
      } else if (environment.env == 'planning') {
        user = (
          await getDoc(
            doc(db, `township/${townshipId}/planningUsers/${userId}`)
          )
        ).data();
        if (!user) {
          user = (
            await getDoc(doc(db, `township/${townshipId}/users/${userId}`))
          ).data();
        }
      } else {
        user = (
          await getDoc(doc(db, `township/${townshipId}/users/${userId}`))
        ).data();
      }

      if (user && user.rights && allowedRights.indexOf(user.rights) != -1) {
        return true;
      }
    }

    this.router.navigate([environment.defaultRoute.id]);
    return false;
  }
}
