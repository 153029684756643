import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  collection,
  deleteField,
  doc,
  getDocs,
  orderBy,
  query,
  setDoc,
  where,
} from 'firebase/firestore';
import moment from 'moment';
import {
  Appointment,
  AppointmentType,
  DayPart,
  PlanningUser,
  Voucher,
  VoucherGroup,
} from 'src/app/interfaces';
import { CustomValidators } from 'src/app/validators/custom-validators';
import { db, functions } from 'src/app/app.component';
import { capitalizeFirstLetter } from '../../helper';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActionType } from 'src/app/enums';
import { httpsCallable } from 'firebase/functions';

export interface DialogData {
  appointment?: Appointment;
  planningUsers: PlanningUser[];
}

@Component({
  selector: 'app-manage-appointment',
  templateUrl: './manage-appointment.component.html',
  styleUrls: ['./manage-appointment.component.scss'],
})
export class ManageAppointmentComponent implements OnInit {
  step: number = 1;
  initialized: boolean = false;
  saving: boolean = false;
  editing = false;
  townshipId = localStorage.getItem('township') as string;
  moment = moment;

  appointmentId: string = this.db.createId();

  whoForm: FormGroup = this.fb.group({
    postal: new FormControl('', [
      Validators.required,
      CustomValidators.postalValidator,
    ]),
    houseNumber: new FormControl('', [
      Validators.required,
      CustomValidators.numberInput(false, false, 0),
    ]),
    houseNumberAddition: new FormControl(''),
    appointmentTypeId: new FormControl(null, [Validators.required]),
    voucherId: new FormControl(null, [Validators.required]),
  });

  dateForm: FormGroup = this.fb.group({
    start: new FormControl(null, [Validators.required]),
    end: new FormControl(null, [Validators.required]),
    dayPartId: new FormControl(null, [Validators.required]),
  });
  selectedDayPart: DayPart;
  viewDate: Date = new Date(new Date().setHours(0, 0, 0, 0));
  viewWeekNumber = moment(this.viewDate).week();
  viewDates = [];

  planningUsersForm: FormGroup = this.fb.group({
    planningUserIds: new FormControl([], [CustomValidators.arrayNotEmpty()]),
    planningUserNames: new FormControl([], [CustomValidators.arrayNotEmpty()]),
  });

  appointmentTypes: AppointmentType[] = [];
  actionType: ActionType = ActionType.created;
  voucherGroups: VoucherGroup[] = [];
  vouchers: Voucher[] = [];
  errorFindingVouchers = false;
  dayParts: DayPart[] = [];
  suggestedTimes: TimeSuggestion[] = [];
  planningUsers: PlanningUser[] = this.data.planningUsers;
  placeholderDistanceLabel = {
    color: '#ADF2C6',
    contrast: false,
    icon: 'sentiment_very_satisfied',
    name: 'Dicht bij',
  };
  simulatedAppointment: Appointment;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialogRef: MatDialogRef<ManageAppointmentComponent>,
    private fb: FormBuilder,
    public db: AngularFirestore
  ) {}

  async ngOnInit() {
    moment.locale('nl');
    await this.getAppointmentTypes();
    await this.getVoucherGroups();
    await this.getDayParts();
    this.getViewDays();

    if (this.data.appointment) {
      this.appointmentId = this.data.appointment.id;
      this.actionType = ActionType.edited;
      await this.initializeEdit();
    }
    this.initialized = true;
  }

  async initializeEdit() {
    this.editing = true;
    // Fill step 1
    this.whoForm.patchValue(this.data.appointment);
    await this.findVouchers();
    // Fill step 2
    this.dateForm.patchValue(this.data.appointment);
    const start = moment(this.data.appointment.start);
    const timeSuggestion: TimeSuggestion = {
      start: this.data.appointment.start,
      end: this.data.appointment.end,
      dayPart: this.dayParts.find(
        (dayPart) => dayPart.id === this.data.appointment.dayPartId
      ),
      day: capitalizeFirstLetter(start.format('dd')),
      month: start.format('MMM').replace('.', ''),
    };
    this.setTimeSuggestion(timeSuggestion, true);
    this.calculateSuggestedTimes();
    // Fill step 3
    this.planningUsersForm.patchValue(this.data.appointment);

    // Go to step 2
    this.step = 2;
  }

  async getAppointmentTypes() {
    const appointmentTypesRef = collection(
      this.db.firestore,
      `township/${this.townshipId}/appointmentTypes`
    );
    const appointmentTypesDocs = await getDocs(
      query(appointmentTypesRef, orderBy('name'))
    );
    appointmentTypesDocs.forEach((appointmentDoc) => {
      const appointmentType = appointmentDoc.data() as AppointmentType;
      appointmentType.id = appointmentDoc.id;
      this.appointmentTypes.push(appointmentType);
    });
  }

  async getVoucherGroups() {
    const voucherGroupsRef = collection(
      db,
      `township/${this.townshipId}/voucherGroups`
    );
    const voucherGroupsDocs = await getDocs(
      query(voucherGroupsRef, orderBy('name'))
    );
    voucherGroupsDocs.forEach((voucherGroupDoc) => {
      const voucherGroup = voucherGroupDoc.data() as VoucherGroup;
      voucherGroup.id = voucherGroupDoc.id;
      this.voucherGroups.push(voucherGroup);
    });
  }

  async getDayParts() {
    const dayPartsRef = collection(
      this.db.firestore,
      `township/${this.townshipId}/dayParts`
    );
    const dayPartsDocs = await getDocs(query(dayPartsRef, orderBy('day')));
    dayPartsDocs.forEach((dayPartDoc) => {
      const dayPart = dayPartDoc.data() as DayPart;
      dayPart.id = dayPartDoc.id;
      this.dayParts.push(dayPart);
    });
    console.log('this.dayParts', [...this.dayParts]);
  }

  getVoucherGroupById(id: string) {
    const voucherGroup = this.voucherGroups.find((group) => group.id === id);
    return voucherGroup;
  }

  getVoucherById(id: string) {
    const voucher = this.vouchers.find((voucher) => voucher.id === id);
    return voucher;
  }

  getAppointmentTypeById(id: string) {
    const appointmentType = this.appointmentTypes.find(
      (appointmentType) => appointmentType.id === id
    );
    return appointmentType;
  }

  nextStep() {
    console.log('this.step', this.step);
    if (this.step === 1) {
      if (!this.whoForm.valid) {
        return this.whoForm.markAllAsTouched();
      }
      this.calculateSuggestedTimes();
    }
    if (this.step === 2) {
      if (!this.dateForm.valid) {
        return this.dateForm.markAllAsTouched();
      }
    }
    if (this.step === 3) {
      if (!this.planningUsersForm.valid) {
        return this.planningUsersForm.markAllAsTouched();
      }
      this.simulatedAppointment = this.appointmentData();
    }
    console.log('appointmentData', this.appointmentData());
    this.step++;
  }

  prevStep() {
    if (this.step === 4) {
      this.simulatedAppointment = null;
    }
    this.step--;
  }

  async findVouchers() {
    if (
      !this.whoForm.controls.postal.valid ||
      !this.whoForm.controls.houseNumber.valid ||
      !this.whoForm.controls.appointmentTypeId.valid
    ) {
      console.log('form invalid');
      return this.whoForm.markAllAsTouched();
    }
    this.errorFindingVouchers = false;
    const form = this.whoForm.value;
    const vouchersRef = collection(
      this.db.firestore,
      `township/${this.townshipId}/vouchers`
    );
    let vouchersQuery = query(
      vouchersRef,
      where('status', '==', 'active'),
      where('postal', '==', form.postal),
      where('houseNumber', '==', form.houseNumber)
    );
    if (form.houseNumberAddition) {
      vouchersQuery = query(
        vouchersRef,
        where('status', '==', 'active'),
        where('postal', '==', form.postal),
        where('houseNumber', '==', form.houseNumber),
        where('houseNumberAddition', '==', form.houseNumberAddition)
      );
    }
    const voucherDocs = await getDocs(vouchersQuery);
    const vouchers = [];
    voucherDocs.forEach((voucherDoc) => {
      const voucher = voucherDoc.data();
      voucher.id = voucherDoc.id;
      voucher.activateDate = voucher.activateDate.toDate();
      console.log('voucher', voucher);
      vouchers.push(voucher);
    });
    if (vouchers.length == 0) {
      this.errorFindingVouchers = true;
    }
    this.vouchers = vouchers;
  }

  setVoucher(voucher: Voucher) {
    this.whoForm.controls.voucherId.setValue(voucher.number);
  }

  calculateSuggestedTimes() {
    const currentDay = this.viewDate.getDay();
    console.log('day', currentDay);
    const dayPartsToday = this.dayParts
      .filter((dayPart) => {
        return dayPart.day === currentDay;
      })
      .sort((a, b) => {
        if (a.startHour > b.startHour) {
          return 1;
        }
        return -1;
      });
    console.log('dayPartsToday', dayPartsToday);
    const appointmentType = this.getAppointmentTypeById(
      this.whoForm.value.appointmentTypeId
    );
    console.log('appointmentType', appointmentType);
    const suggestedTimes = [];
    dayPartsToday.forEach((dayPart) => {
      const dayPartMinutes = (dayPart.endHour - dayPart.startHour) * 60;
      let minutesRemaining = dayPartMinutes;
      console.log('minutesRemaining', minutesRemaining);
      const duration =
        appointmentType.durationMinutes + appointmentType.durationHours * 60;
      console.log('appointmentType.duration', duration);
      while (minutesRemaining > duration) {
        const startTimeInMinutes =
          dayPart.startHour * 60 +
          dayPart.startMinutes +
          (dayPartMinutes - minutesRemaining);
        const startDate = moment(this.viewDate).add(
          startTimeInMinutes,
          'minutes'
        );
        const endDate = moment(this.viewDate).add(
          startTimeInMinutes + duration,
          'minutes'
        );
        console.log('startTimeInMinutes', startTimeInMinutes);
        const timeSuggestion: TimeSuggestion = {
          start: startDate.toDate(),
          end: endDate.toDate(),
          dayPart: dayPart,
          day: capitalizeFirstLetter(startDate.format('dd')),
          month: startDate.format('MMM').replace('.', ''),
        };
        suggestedTimes.push(timeSuggestion);
        minutesRemaining = minutesRemaining - duration - 15;
      }
    });
    console.log('suggestedTimes', suggestedTimes);
    this.suggestedTimes = suggestedTimes;
  }

  setTimeSuggestion(time: TimeSuggestion, onInitialize: boolean) {
    this.dateForm.patchValue({
      start: time.start,
      end: time.end,
      dayPartId: time.dayPart.id,
    });
    this.selectedDayPart = time.dayPart;
    if (!onInitialize) this.nextStep();
  }

  togglePlanningUser(user: PlanningUser) {
    const userName = `${user.firstName} ${user.lastName}`;
    const form = this.planningUsersForm.value;
    const userIdsArray = form.planningUserIds as string[];
    const userNamesArray = form.planningUserNames as string[];
    if (userIdsArray.includes(user.id)) {
      userIdsArray.splice(userIdsArray.indexOf(user.id), 1);
      userNamesArray.splice(userNamesArray.indexOf(userName), 1);
    } else {
      userIdsArray.push(user.id);
      userNamesArray.push(userName);
    }
    this.planningUsersForm.controls.planningUserIds.setValue(userIdsArray);
    this.planningUsersForm.controls.planningUserNames.setValue(userNamesArray);
  }

  getViewDays() {
    console.log('viewDate', this.viewDate);
    const weekday = moment(this.viewDate).weekday();
    console.log('weekday', weekday);
    const currentDayOfWeek = moment(this.viewDate).subtract(weekday, 'days'); // Starts with first day of the week
    console.log('currentDayOfWeek', currentDayOfWeek.toDate());

    const viewDates = [];
    for (let i = 0; i <= 6; i++) {
      console.log('weekday', currentDayOfWeek.weekday());
      viewDates.push({
        date: currentDayOfWeek.toDate(),
        moment: currentDayOfWeek,
        day: capitalizeFirstLetter(currentDayOfWeek.format('dd')),
        month: currentDayOfWeek.format('MMM').replace('.', ''),
      });
      currentDayOfWeek.add(1, 'days').toDate();
    }

    this.viewDates = viewDates;
    console.log('viewDates', this.viewDates);
  }

  setDate(date: Date) {
    this.viewDate = date;
    this.calculateSuggestedTimes();
  }

  nextWeek() {
    this.viewDate = moment(this.viewDate).add(7, 'days').toDate();
    this.viewWeekNumber = moment(this.viewDate).week();
    this.getViewDays();
    this.calculateSuggestedTimes();
  }

  prevWeek() {
    this.viewDate = moment(this.viewDate).subtract(7, 'days').toDate();
    this.viewWeekNumber = moment(this.viewDate).week();
    this.getViewDays();
    this.calculateSuggestedTimes();
  }

  appointmentData() {
    const voucher = this.getVoucherById(this.whoForm.value.voucherId);
    const appointmentType = this.getAppointmentTypeById(
      this.whoForm.value.appointmentTypeId
    );
    const appointment = {
      ...this.whoForm.value,
      ...this.dateForm.value,
      ...this.planningUsersForm.value,
      userName: voucher.name,
      userPhone: voucher.phone,
      userEmail: voucher.email,
      street: voucher.street ?? deleteField(),
      city: voucher.city ?? deleteField(),
      appointmentTypeName: appointmentType.name,
    } as Appointment;
    console.log('appointment', appointment);
    return appointment;
  }

  async save() {
    if (this.saving) {
      return;
    }
    this.saving = true;

    const appointmentRef = doc(
      db,
      `township/${this.townshipId}/appointments/${this.appointmentId}`
    );
    await setDoc(appointmentRef, this.appointmentData(), { merge: true });
    const callable = httpsCallable(functions, 'pipedriveCreateActivity');
    const result = await callable({
      townshipId: this.townshipId,
      appointmentId: this.appointmentId,
      actionType: this.actionType,
    });
    this.dialogRef.close(true);
  }

  errorMessage(control) {
    switch (control) {
      case 'postal':
        if (this.whoForm.controls.postal.hasError('postalValidator')) {
          return 'De ingevulde postcode is incorrect';
        }
        if (this.whoForm.controls.postal.hasError('required')) {
          return 'Een postcode is vereist';
        }
        break;
      case 'houseNumber':
        if (this.whoForm.controls.houseNumber.hasError('numberValidator')) {
          return 'Het ingevulde huisnummer is incorrect';
        }
        if (this.whoForm.controls.houseNumber.hasError('required')) {
          return 'Een huisnummer is vereist';
        }
        break;
    }
  }
}

interface TimeSuggestion {
  start: Date;
  end: Date;
  dayPart: DayPart;
  day: string;
  month: string;
}
