import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Appointment, PlanningUser } from 'src/app/interfaces';
import { AppointmentDeleteComponent } from '../appointment-delete/appointment-delete.component';
import { deleteDoc, doc } from 'firebase/firestore';
import { db, functions } from 'src/app/app.component';
import { httpsCallable } from 'firebase/functions';
import { ActionType } from 'src/app/enums';

export interface DialogData {
  appointment: Appointment;
  planningUsers: PlanningUser[];
}

@Component({
  selector: 'app-appointment-details',
  templateUrl: './appointment-details.component.html',
  styleUrls: ['./appointment-details.component.scss'],
})
export class AppointmentDetailsComponent {
  townshipId = localStorage.getItem('township') as string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<AppointmentDetailsComponent>
  ) {}

  cancelAppointment() {
    const dialogRef = this.dialog.open(AppointmentDeleteComponent, {
      width: '250px',
    });
    dialogRef.afterClosed().subscribe(async (deleteAppointment) => {
      if (deleteAppointment) {
        const appointmentRef = doc(
          db,
          `township/${this.townshipId}/appointments/${this.data.appointment.id}`
        );
        const callable = httpsCallable(functions, 'pipedriveCreateActivity');
        const result = await callable({
          townshipId: this.townshipId,
          appointmentId: this.data.appointment.id,
          actionType: ActionType.canceled,
        });
        await deleteDoc(appointmentRef);
        this.dialogRef.close();
      }
    });
  }
}
